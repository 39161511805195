import React, { useState, useEffect } from "react";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import { Editor } from '@tinymce/tinymce-react';
import blog from "../services/blog";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({

    BlogCatagoryId: yup.number().required(),
    MetaTitle: yup.string().required(),
    MetaKeywords: yup.string(),
    MetaDescription: yup.string().required(),
    VirtualUrl: yup.string().required()
}).required();

function AddBlog() {
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });

    const [categories, setCategories] = useState([]);
    const [blogDescription, setBlogDescription] = useState('');
    const handleEditorChange = (content) => {
        setBlogDescription(content);
    };
    useEffect(() => {
        async function fetchCategories() {
            try {
                const categoryData = await blog.BlogCategoryList();
                setCategories(categoryData.data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }
        fetchCategories();
    }, []);  

    const selectedCategory = watch("BlogTitle");

    useEffect(() => {
        if (selectedCategory) {
            const virtualUrl = selectedCategory
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("VirtualUrl", virtualUrl);
        }
    }, [selectedCategory, setValue]);

    const onSubmit = async (data) => {     
        try {
            console.log(data);
            const formData = new FormData();
            if (data.file && data.file[0]) {
                formData.append("file", data.file[0]); // Append the file if it exists
            }
            formData.append("BlogCatagoryId", data.BlogCatagoryId);
            formData.append("BlogTitle", data.BlogTitle);
            formData.append("BlogDescription", blogDescription);
            formData.append("MetaTitle", data.MetaTitle);
            formData.append("MetaKeywords", data.MetaKeywords);
            formData.append("MetaDescription", data.MetaDescription);
            formData.append("VirtualUrl", data.VirtualUrl);
    

            // console.log(data);
            const response = await blog.addUpdateBlog(formData); // Remove the second argument
            
            if (response.data === -1) {
                toastr.error('Blog already exists !!');                   
            } else {
                toastr.success('Blog added successfully');
            }       
        } catch (error) {
            toastr.error('Something Went Wrong !');
            console.error('Error submitting form:', error);
        }       
    };
    function convertToPlainText(htmlContent) {
        // Create a temporary div element to parse the HTML content
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlContent;
        // Extract plain text from the parsed HTML content
        return tempDiv.innerText;
    }

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Add Blog</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Add Blog</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Blog</h3>
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="CategoryName">Category Name</label>
                                                    <select
                                                        className="form-control"
                                                        name="BlogCatagoryId"
                                                        id="BlogCatagoryId"
                                                        {...register("BlogCatagoryId")}
                                                    >
                                                        <option value="">Select</option>
                                                        {Array.isArray(categories) && categories.map(category => (
                                                            <option key={category.blogCatagoryId} value={category.blogCatagoryId}>{category.categoryName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="BlogTitle">Blog Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="BlogTitle"
                                                        placeholder="Enter Blog Title" 
                                                        name="BlogTitle" 
                                                        {...register("BlogTitle")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="BlogDescription">Blog Description</label>
                                                    <Editor 
                                                        id="BlogDescription" 
                                                        name="BlogDescription" 
                                                        apiKey='m0arsxlge8ikat6f412tdrr3p6vulctjb0m4rzivel855e77'
                                                        textareaName='description'  
                                                        init={{
                                                            plugins: 'lists link image paste help wordcount',
                                                            toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                                                        }}
                                                        onEditorChange={handleEditorChange}
                                                    />
                                                </div>
                                                <div className="form-group">
    <label htmlFor="exampleInputFile">File input</label>
    <div className="input-group">
        <div className="custom-file">
            <input
                type="file"
                className="custom-file-input"
                id="exampleInputFile"
                name="file"
                {...register("file")}
            />
            <label
                className="custom-file-label"
                htmlFor="exampleInputFile"
            >
                Choose file
            </label>
        </div>
        <div className="input-group-append">
            <span className="input-group-text">Upload</span>
        </div>
    </div>
</div>

                                                <div className="form-group">
                                                    <label htmlFor="MetaTitle">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaTitle"
                                                        placeholder="Enter Meta Title" 
                                                        name="MetaTitle" 
                                                        {...register("MetaTitle")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaKeywords">Meta Keywords</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="MetaKeywords"
                                                        placeholder="Enter Meta Keywords" 
                                                        name="MetaKeywords" 
                                                        {...register("MetaKeywords")}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="MetaDescription">Meta Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="MetaDescription"
                                                        placeholder="Enter Meta Description" 
                                                        rows={5} 
                                                        name="MetaDescription" 
                                                        {...register("MetaDescription")}
                                                    ></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="VirtualUrl">Virtual Url</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="VirtualUrl"
                                                        placeholder="Enter Virtual Url"
                                                        name="VirtualUrl"
                                                        value={watch("VirtualUrl")} // Setting value to watch("VirtualUrl") ensures the field reflects the current state
                                                        {...register("VirtualUrl")}
                                                        readOnly // Making the input field read-only
                                                    />
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                                <a href="/blog-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default AddBlog;
