import React, { Component } from "react";
import DoLogin from "../services/manageLogin";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
    Email: yup.string().required(),
    Password: yup.string().required()
    // age: yup.number().positive().integer().required(),
  }).required();

function Login(){
 
    const { register, handleSubmit, formState:{ errors } } = useForm({
        resolver: yupResolver(schema)
      });
      const onSubmit = data => DoLogin(data);      
        return(
            <div className="hold-transition login-page">
           <div className="login-box">
  <div className="login-logo">
    <a href="#"><b>MyCareer</b>Xpert</a>
  </div>
  {/* /.login-logo */}
  <div className="card">
    <div className="card-body login-card-body">
      <p className="login-box-msg">Sign in to start your session</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group mb-3">
          <input type="email" className="form-control" name="Email" {...register("Email")} placeholder="Email" />
       
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope" />
            </div>
          </div>
          {errors.Email && <p className="invalid-feedback">{errors.Email.message}</p>}
        </div>
        <div className="input-group mb-3">
          <input type="password" autoComplete="off" className="form-control" name="Password" {...register("Password")} placeholder="Password" />
      
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock" />
            </div>
          </div>
          {errors.Password && <p className="invalid-feedback">{errors.Password.message}</p>}
        </div>
        <div className="row">
          <div className="col-8">
            <div className="icheck-primary">
             
            </div>
          </div>
          {/* /.col */}
          <div className="col-4">
            <button type="submit" className="btn btn-primary btn-block">Sign In</button>
          </div>
          {/* /.col */}
        </div>
      </form>
   
    </div>
    {/* /.login-card-body */}
  </div>
</div></div>

)
    
}

export default Login;