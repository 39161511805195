// Import necessary dependencies
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router} from 'react-router-dom';
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Wrap your App component inside a Router component
const Root = () => (
  <React.StrictMode>
    <Router>
    <App />
    </Router>
  </React.StrictMode>
);

// Render the Root component
ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
reportWebVitals();
