import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./Auth/login.component";
import AddBlogCategory from "./Blog/add-blog-category.component";
import EditBlogCategory from "./Blog/edit-blog-category.component";
import AddBlog from "./Blog/add-blog.component";
import EditBlog from "./Blog/edit-blog.component";
import Dashboard from "./Dashboard/career-dashboard.component";
import BlogCategoryList from "./Blog/blog-category-list.component";
import BlogList from "./Blog/blog-list.component";
import ProtectedRoute from "./provider/ProtectedRoute";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path='/add-blog-category' element={<ProtectedRoute component={AddBlogCategory} />} />
        <Route path='/dashboard' element={<ProtectedRoute component={Dashboard} />} />
        <Route path='/edit-blog-category' element={<ProtectedRoute component={EditBlogCategory} />} />
        <Route path='/edit-blog' element={<ProtectedRoute component={EditBlog} />} />
        <Route path='/add-blog' element={<ProtectedRoute component={AddBlog} />} />
        <Route path='/blog-list' element={<ProtectedRoute component={BlogList} />} />
        <Route path='/blog-category-list' element={<ProtectedRoute component={BlogCategoryList} />} />
      </Routes>
    </div>
  );
};

export default App;
