import { useEffect } from 'react';
import { Navigate, Link, useNavigate } from 'react-router-dom';

function ProtectedRoute(props){
  const { component: Component } = props; // Destructure 'component' from props
  const navigate = useNavigate();
  useEffect(() => {
    let login = localStorage.getItem('userToken');
    if(!login){
      navigate('/');
    }
  }, []); // Add an empty dependency array to useEffect to run only once

  return <Component />; // Return Component directly without curly braces
}

export default ProtectedRoute;
