import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const DoLogin = (credentials) => {
  // Check if the user is already logged in
  const login = localStorage.getItem('userToken');
  if (login) {
    // If already logged in, redirect to dashboard
    window.location.href = '/dashboard';
    return;
  }

  return fetch(process.env.REACT_APP_API_URL + 'auth/GetLogin', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(credentials),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (data === -1) {
        toastr.error('Login failed!');
      } else {
        localStorage.setItem('userToken', data.token);
        toastr.success('Login successful');
        window.location.href = '/dashboard';
      }
    })
    .catch((error) => {
      console.error('Fetch error:', error);
      toastr.error('Something went wrong!');
    });
};

export default DoLogin;
