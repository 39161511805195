import React,{ Component } from "react";

function FooterComponent(){

        return(
          <footer className="main-footer">
  <strong>Copyright © 2024 <a href="#">MyCareerXpert</a>.</strong>
  All rights reserved.
</footer>

        )
    
}

export default FooterComponent;