import React,{ Component } from "react";

function HeaderComponent(){
      
  function Logout() {
    // Remove the user token from local storage
    localStorage.removeItem('userToken');
    window.location.href="/";
  }
  
        return(
         <nav className="main-header navbar navbar-expand navbar-white navbar-light">
  {/* Left navbar links */}
  <ul className="navbar-nav">
    <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
    </li>
  
  </ul>
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto">
    {/* Navbar Search */}
  
   
    <li className="nav-item">
      <a className="nav-link" data-widget="fullscreen" href="#" role="button">
        <i className="fas fa-expand-arrows-alt" />
      </a>
    </li>
    <li className="nav-item dropdown">
        <a className="nav-link" data-toggle="dropdown" href="javascript:void(0)" onClick={Logout}>
        <i className="fas fa-lock"></i> Logout
      
        </a>
        
      </li>
  </ul>
</nav>

        )
    
}
export default HeaderComponent;