import React, { useState, useEffect } from "react";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import blog from "../services/blog";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
    blogCatagoryId: yup.number(),
    categoryName: yup.string().required(),
    metaTitle: yup.string().required(),
    metaKeywords: yup.string(),
    metaDescription: yup.string().required(),
    virtualUrl: yup.string().required()
}).required();

function EditBlogCategory() {
    const [user_info, setUserInfo] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission state
    const [isLoaded, setIsLoaded] = useState(false); // Track if data is loaded

    useEffect(() => {
        LoadData();
    }, []);

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    var query = window.location.search;
    const params = new URLSearchParams(query);
    
    function LoadData() {
        blog.GetBlogCategory(params.get('id'))
            .then(response => {
                setUserInfo(response.data);
                setIsLoaded(true); // Set data loaded to true
            })
            .catch((error) => {
                toastr.error('Something Went Wrong !');
            });
    }

    const onSubmit = data => {
        if (!isSubmitting) {
            setIsSubmitting(true);

            
            //console.log(data);
            // Perform form submission logic
            blog.AddUpdateBlogCategory(data)
                .then(response => {
                    // console.log("API Response:", response);
                    if (response.data.message === -1) {
                        toastr.error('Blog category already exists !!');
                    } else {
                        toastr.success('Blog category updated successfully');
                    }
                })
                .catch((error) => {
                    console.error("API Error:", error);
                    toastr.error('Something Went Wrong !');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...user_info, [name]: value });
    };

    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Edit Blog Category</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                        <li className="breadcrumb-item active">Edit Blog Category</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Blog Category</h3>
                                        </div>
                                        {isLoaded && // Render form only when data is loaded
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <input type="hidden" id="blogCatagoryId" name="blogCatagoryId" value={params.get('id') || 0} {...register("blogCatagoryId")} />
 
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <label htmlFor="categoryName">Category Name</label>
                                                        <input type="text" className="form-control" id="categoryName" name="categoryName" placeholder="Enter Category Name" {...register("categoryName")} value={user_info.categoryName || '' }  onChange={handleChange} />
                                                        {errors.categoryName && <p className="invalid-feedback">{errors.categoryName.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="metaTitle">Meta Title</label>
                                                        <input type="text" className="form-control" id="metaTitle" name="metaTitle" placeholder="Enter Meta Title" {...register("metaTitle")} value={user_info.metaTitle || ''} onChange={handleChange}/>
                                                        {errors.metaTitle && <p className="invalid-feedback">{errors.metaTitle.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="MetaKeywords">Meta Keywords</label>
                                                        <input type="text" className="form-control" id="metaKeywords" name="metaKeywords" placeholder="Enter Meta Keywords" {...register("metaKeywords")} value={user_info.metaKeywords || ''} onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="metaDescription">Meta Description</label>
                                                        <textarea className="form-control" id="metaDescription" placeholder="Enter Meta Description" rows={5} name="metaDescription" {...register("metaDescription")} value={user_info.metaDescription || ''} onChange={handleChange}></textarea>
                                                        {errors.metaDescription && <p className="invalid-feedback">{errors.metaDescription.message}</p>}
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="virtualUrl">Virtual Url</label>
                                                        <input type="text" className="form-control" id="virtualUrl" name="virtualUrl" {...register("virtualUrl")} value={user_info.virtualUrl || ''} placeholder="Enter Virtual Url" onChange={handleChange} readOnly/>
                                                        {errors.virtualUrl && <p className="invalid-feedback">{errors.virtualUrl.message}</p>}
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
                                                    <a href="/blog-category-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                                </div>
                                            </form>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    )
}

export default EditBlogCategory;
