import axios from "axios";

var authOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  },
};

class BlogService {
  AddUpdateBlogCategory(data) {
    return axios.post(
      process.env.REACT_APP_API_URL + "BlogCategory/AddOrUpdateBlogCategory",
      data,
      authOptions
    );
  }
  GetBlogCategory(id) {
    return axios.get(
      process.env.REACT_APP_API_URL + "BlogCategory/GetBlogCategory?id=" + id,
      authOptions
    );
  }
  BlogCategoryList() {
    return axios.get(
      process.env.REACT_APP_API_URL +
        "BlogCategory/GetBlogCatList?IsActive=true",
      authOptions
    );
  }
  DeleteBlogCategory(id) {
    return axios.get(
      process.env.REACT_APP_API_URL + "BlogCategory/DeleteBlogCat?id=" + id,
      authOptions
    );
  }
  BlogList(pageNum, pageSize) {
    return axios.get(
      process.env.REACT_APP_API_URL +
        "BlogCategory/BlogList?pageSize=" +
        pageSize +
        "&pageNum=" +
        pageNum,
      authOptions
    );
  }
  DeleteBlog(id) {
    return  axios.get(
      process.env.REACT_APP_API_URL + "BlogCategory/DeleteBlog?id=" + id,
      authOptions
    );
  }
  async GetBlog(id) {
    return await axios.get(
      process.env.REACT_APP_API_URL + "BlogCategory/GetBlogData?Id=" + id,
      authOptions
    );
  }
  async addUpdateBlog(formData) {
    try {
        // Make sure to set the appropriate headers for FormData
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("userToken"),
            },
        };

        // Make the API call with the formData
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "BlogCategory/AddOrUpdateBlog",
            formData,
            config
        );

        return response.data; // Return the response data
    } catch (error) {
        // Handle errors
        throw new Error("Failed to add or update blog: " + error.message);
    }
}

}

export default new BlogService();
