import React, { useState, useEffect  } from "react";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import { Editor } from '@tinymce/tinymce-react';
import blog from "../services/blog";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
const schema = yup.object({
    blogId: yup.number(),
    blogCatagoryId: yup.number().required(),
    metaTitle: yup.string().required(),
    metaKeywords: yup.string(),
    blogImage: yup.string(),
    metaDescription: yup.string().required(),
    virtualUrl: yup.string().required()
}).required();

function EditBlog() {
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm({
        resolver: yupResolver(schema)
    });
    const [user_info, setUserInfo] = useState({
        blogDescription: '' // Initialize blogDescription here
    });
    const [categories, setCategories] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    var query = window.location.search;
    const params = new URLSearchParams(query);
    useEffect(() => {
        async function fetchCategoriesAndData() {
            try {
                const categoryData = await blog.BlogCategoryList();
                setCategories(categoryData.data);
                const response = await blog.GetBlog(params.get('id'));
                setUserInfo(response.data);
                setIsLoaded(true);
            } catch (error) {
               // toastr.error('Something Went Wrong !');
                console.error('Error fetching blog data:', error);
            }
        }
        fetchCategoriesAndData();
    }, []);
    

   
    
    // async function LoadData() {
    //     try {
    //         const response = await blog.GetBlog(params.get('id'));
    //         setUserInfo(response.data);
    //         setIsLoaded(true);
    //     } catch (error) {
    //         toastr.error('Something Went Wrong !');
    //         console.error('Error fetching blog data:', error);
    //     }
    // }

    const selectedCategory = watch("BlogTitle");

    useEffect(() => {
        if (selectedCategory) {
            const virtualUrl = selectedCategory
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("VirtualUrl", virtualUrl);
        }
    }, [selectedCategory, setValue]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserInfo({ ...user_info, [name]: value });
    };

    const handleEditorChange = (content) => {
        setUserInfo({ ...user_info, blogDescription: content });
    };

    const onSubmit = async (data) => {  
        console.log(data);   
        try {
            const formData = new FormData();
            if (data.file && data.file[0]) {
                formData.append("file", data.file[0]); // Append the file if it exists
            }
            formData.append("BlogId", params.get('id') || 0);
            formData.append("BlogCatagoryId", data.blogCatagoryId);
            formData.append("BlogTitle", data.blogTitle);
            formData.append("BlogImage", data.blogImage);
            formData.append("BlogDescription", user_info.blogDescription); // Use user_info directly
            formData.append("MetaTitle", data.metaTitle);
            formData.append("MetaKeywords", data.metaKeywords);
            formData.append("MetaDescription", data.metaDescription);
            formData.append("VirtualUrl", data.virtualUrl);

            const response = await blog.addUpdateBlog(formData);
            
            if (response.data === -1) {
                toastr.error('Blog already exists !!');
            } else {
                toastr.success('Blog updated successfully');
            }       
        } catch (error) {
            toastr.error('Something Went Wrong !');
            console.error('Error submitting form:', error);
        }       
    };


    return (
        <div className="hold-transition sidebar-mini">
            <div className="wrapper">
                <HeaderComponent />
                <MenuComponent />
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Edit Blog</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item">
                                            <a href="/dashboard">Home</a>
                                        </li>
                                        <li className="breadcrumb-item active">Edit Blog</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Blog</h3>
                                        </div>
                                        {isLoaded && // Render form only when data is loaded
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <input type="hidden" id="blogId" name="blogId" value={params.get('id') || 0} {...register("blogId")} />
                                            <input type="hidden" id="blogImage" name="blogImage" value={user_info.blogImage || 0} {...register("blogImage")} />
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="CategoryName">Category Name</label>
                                                    <select
                                                        className="form-control"
                                                        name="blogCatagoryId"
                                                        id="blogCatagoryId"
                                                        defaultValue={user_info.blogCatagoryId} // Set default value here
                                                        {...register("blogCatagoryId")}
                                                    >
                                                        <option value="">Select</option>
                                                        {Array.isArray(categories) && categories.map(category => (
                                                            <option key={category.blogCatagoryId} value={category.blogCatagoryId}>{category.categoryName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="blogTitle">Blog Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="blogTitle"
                                                        placeholder="Enter Blog Title" 
                                                        name="blogTitle" 
                                                        {...register("blogTitle")}  value={user_info.blogTitle || ''}  onChange={handleChange}
                                                       
                                                    />
                                                     {errors.blogTitle && <p className="invalid-feedback">{errors.blogTitle.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="blogDescription">Blog Description</label>
                                                    <Editor 
                                                        id="blogDescription" 
                                                        name="blogDescription" 
                                                        apiKey='m0arsxlge8ikat6f412tdrr3p6vulctjb0m4rzivel855e77'
                                                        textareaName='blogDescription'  
                                                        init={{
                                                            plugins: 'lists link image paste help wordcount',
                                                            toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                                                        }}
                                                        value={user_info.blogDescription || ''} // Control the Editor value
                                                        onEditorChange={handleEditorChange} // Handle change events
                                                    />

                                                </div>
                                                <div className="form-group">
    <label htmlFor="exampleInputFile">File input</label>
    <div className="input-group">
        <div className="custom-file">
            <input
                type="file"
                className="custom-file-input"
                id="exampleInputFile"
                name="file"
                {...register("file")}
            />
            <label
                className="custom-file-label"
                htmlFor="exampleInputFile"
            >
                Choose file
            </label>
        </div>
        <div className="input-group-append">
            <span className="input-group-text">Upload</span>
        </div>
    </div>
</div>

                                                <div className="form-group">
                                                    <label htmlFor="metaTitle">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="metaTitle"
                                                        placeholder="Enter Meta Title" 
                                                        name="metaTitle" 
                                                        {...register("metaTitle")}  value={user_info.metaTitle || ''}  onChange={handleChange}
                                                    />
                                                     {errors.metaTitle && <p className="invalid-feedback">{errors.metaTitle.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="metaKeywords">Meta Keywords</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="metaKeywords"
                                                        placeholder="Enter Meta Keywords" 
                                                        name="metaKeywords" 
                                                        {...register("metaKeywords")}  value={user_info.metaKeywords || ''}  onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="metaDescription">Meta Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        id="metaDescription"
                                                        placeholder="Enter Meta Description" 
                                                        rows={5} 
                                                        name="metaDescription" 
                                                        {...register("metaDescription")}  value={user_info.metaDescription || ''}  onChange={handleChange}
                                                    ></textarea>
                                                     {errors.metaDescription && <p className="invalid-feedback">{errors.metaDescription.message}</p>}
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="virtualUrl">Virtual Url</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="virtualUrl"
                                                        placeholder="Enter Virtual Url"
                                                        name="virtualUrl"
                                                        value={user_info.virtualUrl || ''} // Setting value to watch("VirtualUrl") ensures the field reflects the current state
                                                        {...register("virtualUrl")}
                                                        readOnly // Making the input field read-only
                                                    />
                                                     {errors.virtualUrl && <p className="invalid-feedback">{errors.virtualUrl.message}</p>}
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">
                                                    Submit
                                                </button>
                                                <a href="/blog-list" className="btn btn-secondary ml-auto float-right">Back</a>
                                            </div>
                                        </form>
}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FooterComponent />
        </div>
    );
}

export default EditBlog;
