import React, { useState, useEffect  } from "react";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import blog from "../services/blog";
import moment from 'moment'

function BlogList(){
  
    const [mypost, setPostArray] = useState([]);
  
    function DeleteBlog(event, elem) {
      event.preventDefault();
      if (window.confirm("Are you sure you want to delete this blog?")) {
        blog.DeleteBlog(elem)
          .then(response => {
            // console.log(response);
            if (response.data === 1) {
              toastr.error('Blog Deleted Successfully')
              loadData(1,20);
            } else {
              toastr.error('Something Went Wrong !');
            }
          })
          .catch((error) => {
            toastr.error('Something Went Wrong !');
          })
      }
    }
    
  
    
  
    useEffect(() => {
      loadData(1,20);
    
    }, []);  
    
  
    function loadData(pageNum, pageSize){
      blog.BlogList(pageNum,pageSize)  
      .then(response => {
           console.log(response.data);
           // setPostArray([response.data]);
           setPostArray(response.data);
           //console.log(setPostArray);
        }
      )
      .catch((error) => {
        //toastr.error('Something Went Wrong !');
      })
    }
        return(
       <div className="hold-transition sidebar-mini">


  <div className="wrapper">
    <HeaderComponent></HeaderComponent>
  <MenuComponent></MenuComponent>
 <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <section className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1>Blog List</h1>
        </div>
        <div className="col-sm-6">
        <a href="/add-blog" className="btn btn-primary float-right"><i class="fas fa-plus" style={{fontSize: '0.8rem'}}></i> Add Blog</a>
        </div>
      </div>
    </div>{/* /.container-fluid */}
  </section>
  {/* Main content */}
  <section className="content">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Blog List</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <table id="example2" className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Created Date</th>
                    <th style={{width: '15%'}}>Blog Category</th>
                    <th style={{width: '45%'}}>Blog Title</th>
                    <th style={{textAlign: 'center'}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                {mypost.map((item, index) => (
                   <tr key={index}>
                    <td>{moment(item.createdDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                    <td>{item.categoryName}</td>
                    <td>{item.blogTitle}</td>
                    <td align="center">
                        <a href={"/edit-blog?id="+item.blogId}><i className="far fa-edit"></i></a>
                        {/* <a style={{ marginLeft: '10px' }} href={"/delete-blog?id=" + item.blogId}><i className="fas fa-trash-alt"></i></a> */}
                        <a
  style={{ marginLeft: '10px' }}
  href="javascript:void(0)"
  onClick={(event) => DeleteBlog(event, item.blogId)}
>
  <i className="fas fa-trash-alt"></i>
</a>

                    </td>
                  </tr>
               ))}
                 
                </tbody>
              
              </table>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        
          {/* /.card */}
        </div>
        {/* /.col */}
      </div>
      {/* /.row */}
    </div>
    {/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>
</div>
  <FooterComponent></FooterComponent>
</div>

)
}

export default BlogList;