import React, { useEffect } from "react";
import HeaderComponent from "../_partials/header.component";
import FooterComponent from "../_partials/footer.component";
import MenuComponent from "../_partials/menu.component";
import blog from "../services/blog";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const schema = yup.object({
    CategoryName: yup.string().required(),
    MetaTitle: yup.string().required(),
    MetaKeywords: yup.string().required(),
    MetaDescription: yup.string().required(),
    VirtualUrl: yup.string().required()
    // age: yup.number().positive().integer().required(),
  }).required();
  
  function AddBlogCategory(){
       // const myServiceInstance = new AddUserProfile();  
    const { register, handleSubmit,reset, formState:{ errors },setValue, watch } = useForm({
      resolver: yupResolver(schema)
    });
  
    //console.log(process.env.REACT_APP_API_URL);
    const selectedCategory = watch("CategoryName");

    useEffect(() => {
        if (selectedCategory) {
            const virtualUrl = selectedCategory
                .replace(/[^a-z0-9\s]/gi, '')
                .replace(/^\s+/g, '')
                .replace(/\s+$/g, '')
                .replace(/[_\s]/g, '-')
                .toLowerCase()
                .substring(0, 140);
            setValue("VirtualUrl", virtualUrl);
        }
    }, [selectedCategory, setValue]);
    const onSubmit = data => {     
      
        blog.AddUpdateBlogCategory(data)
  
      .then(response => {   
      
           if(response.data===-1){
             toastr.error('Blog categry already exists !!');                   
           }
           else {
             toastr.success('Blog category added successfully');
             reset(); 
           }       
        }
      )
      .catch((error) => {
        toastr.error('Something Went Wrong !');      
      });       
    };
        return(
           <div className="hold-transition sidebar-mini">
  <div className="wrapper">
    <HeaderComponent></HeaderComponent>
  <MenuComponent></MenuComponent>
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Add Blog Category</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
                <li className="breadcrumb-item active">Add Blog Category</li>
              </ol>
            </div>
          </div>
        </div>{/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            {/* left column */}
            <div className="col-md-12">
              {/* general form elements */}
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Blog Category</h3>
                </div>
                {/* /.card-header */}
                {/* form start */}
                <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Category Name</label>
                      <input type="text" className="form-control" id="CategoryName" name="CategoryName" placeholder="Enter Category Name"  {...register("CategoryName")}/>
                      {errors.CategoryName && <p className="invalid-feedback">{errors.CategoryName.message}</p>}
                    </div>
                    
                 
                 
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Meta Title</label>
                      <input type="text" className="form-control" id="MetaTitle" name="MetaTitle" placeholder="Enter Meta Title"  {...register("MetaTitle")}/>
                      {errors.MetaTitle && <p className="invalid-feedback">{errors.MetaTitle.message}</p>}
                    </div>
                    
                
             
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Meta Keywords</label>
                      <input type="text" className="form-control" id="MetaKeywords" name="MetaKeywords" placeholder="Enter Meta Keywords"  {...register("MetaKeywords")}/>
                    </div>                    
                
                
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Meta Description</label>
                      <textarea
                              className="form-control"
                              id="MetaDescription"
                              placeholder="Enter Meta Description" rows={5} name="MetaDescription" {...register("MetaDescription")}
                            ></textarea>
                              {errors.MetaDescription && <p className="invalid-feedback">{errors.MetaDescription.message}</p>}
                    </div>                    
                  
                 
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Virtual Url</label>
                      <input type="text" className="form-control" id="VirtualUrl" name="VirtualUrl" {...register("VirtualUrl")} placeholder="Enter Virtual Url" readOnly/>
                      {errors.VirtualUrl && <p className="invalid-feedback">{errors.VirtualUrl.message}</p>}
                    </div>                    
                    </div>
                  {/* /.card-body */}
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">Submit</button>
                    <a href="/blog-category-list" className="btn btn-secondary ml-auto float-right">Back</a>
                  </div>
                </form>
              </div>
             
              {/* Horizontal Form */}
            
              {/* /.card */}
            </div>
            {/*/.col (left) */}
            {/* right column */}
         
            {/*/.col (right) */}
          </div>
          {/* /.row */}
        </div>{/* /.container-fluid */}
      </section>
      {/* /.content */}
    </div>
  </div>
  <FooterComponent></FooterComponent>
</div>

)
}

export default AddBlogCategory;